import React from 'react';
import notFound from '../../images/not-found.svg';
import './styles.scss';
import { Section } from '../Section';

export const NotFound = () => {
  return (
    <Section id="not-found" isFullHeight>
      <div className="not-found">
        <img alt="404 Not Found" src={notFound} className="not-found__image" />
        <h2 className="section__title--primary">Page not found</h2>
        <a href="/">Click to redirect to home page</a>
      </div>
    </Section>
  );
};
